import React from "react"
import moment from 'moment'
import momentArLocalization from 'moment/locale/ar'
import { useTranslation } from 'react-i18next'

export default ({author, date, duration}) => {
  const { t, i18n } = useTranslation()
  const { language = 'en' } = i18n

  return (
    <small>
      {
        (duration && (
          <>
            <span>زمن المشاهدة {duration} دقيقة</span>
          </>
        )) || null
      }

      {
        (duration && (author || date) && (
          <>
            &nbsp;|&nbsp;
          </>
        )) || null
      }
      {
        (author && (
          <>
            <span>
              {t('author')}&nbsp;
              {t('shehab')}
              </span>
          </>
        )) || null
      }
      {
        (date && (duration || author) && (
          <>
            &nbsp;|&nbsp;
          </>
        )) || null
      }
      {
        (date && (
          <time>{moment(date).locale(language).format("Do MMMM YYYY")}</time>
        )) || null
      }
    </small>
  )
}
